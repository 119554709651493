import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Pancakes`}</h1>
    <h3>{`INGREDIENTS`}</h3>
    <p>{`Purchase`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.amazon.com/Bobs-Red-Mill-Gluten-Baking/dp/B07PPPW7H5"
        }}>{`Flour`}</a>{`, Bob's Red Mill 1:1, 1 cup `}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.amazon.com/Delicious-Organic-Unsweetened-Coconut-Milk/dp/B00C0TALW6"
        }}>{`Coconut milk`}</a>{`, 1 cup `}</li>
    </ul>
    <p>{`On hand`}</p>
    <ul>
      <li parentName="ul">{`Baking soda, 1 tablespoon`}</li>
      <li parentName="ul">{`Maple syrup`}</li>
      <li parentName="ul">{`Salt`}</li>
      <li parentName="ul">{`Vanilla extract`}</li>
      <li parentName="ul">{`Egg`}</li>
    </ul>
    <h3>{`DIRECTIONS`}</h3>
    <ol>
      <li parentName="ol">{`Preheat griddle `}</li>
      <li parentName="ol">{`Mix dry ingredients and whisk together (flour, salt, baking soda, vanilla extract)`}</li>
      <li parentName="ol">{`Add wet ingredients, egg, vanilla, maple syrup, coconut milk, whisk until smooth`}</li>
      <li parentName="ol">{`Add more coconut milk if not smooth`}</li>
      <li parentName="ol">{`Pour onto griddle, wait for bubbles in center`}</li>
      <li parentName="ol">{`Flip, wait another minute`}</li>
      <li parentName="ol">{`Serve with maple syrup`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      